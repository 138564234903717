










import { Component, Vue } from "vue-property-decorator";
import { db } from "@/scripts/firebase";
import VideoList from "@/components/VideoList.vue";
import Pagination from "@/components/Pagination.vue";

@Component({
  components: {
    VideoList,
    Pagination
  }
})
export default class MakerVideoView extends Vue {
  videos: Array<object> = [];

  created() {
    const videoCollectionRef = db.collection("videos");
    videoCollectionRef
      .where("maker", "==", this.getMakerHeading())
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.id, " => ", doc.data());
          this.videos.push(doc.data());
        });
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
  }

  getMakerHeading() {
    return this.$route.params.maker;
  }
}
